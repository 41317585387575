import React from "react"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import colors from "../../styles/colors"
import richText from "../../utils/richText"
import { Header3 } from "../typography"

const Wrapper = styled.div`
  color: ${colors.creamLight};
  padding: 40px 24px;

  position: relative;

  > div {
    margin-bottom: 40px;
  }

  &:after {
    content: "";

    position: absolute;
    left: 50%;
    bottom: 0;

    transform: translateX(-50%);

    height: 1px;
    width: 100vw;

    background: ${colors.creamLight};
  }

  ${Header3} {
    margin-bottom: 16px;
  }

  ul,
  ol {
    margin: 0;
    padding-left: 24px;
  }

  @media ${breakpoints.tablet} {
    padding: 120px 24px;

    > div {
      display: flex;

      max-width: 760px;
      margin: 0 auto;

      &:first-child {
        margin-bottom: 80px;
      }

      ${Header3} {
        flex: 0 0 240px;
        width: 240px;
        margin-right: 40px;
      }
    }
  }
`

export default function RecipeBody({ ingredients, instructions }) {
  return (
    <Wrapper>
      <div>
        <Header3>Ingredients</Header3>
        <div>{richText(ingredients)}</div>
      </div>
      <div>
        <Header3>Instructions</Header3>
        <div>{richText(instructions)}</div>
      </div>
    </Wrapper>
  )
}

import { graphql } from "gatsby"
import React from "react"
import ProductSlideshow from "../components/product/ProductSlideshow"
import Layout from "../components/layout"
import ProductDetails from "../components/product/ProductDetails"
import Product from "../components/product/Product"
import IngredientHighlights from "../components/product/IngredientHighlight"
import RecipeHeader from "../components/recipes/RecipeHeader"
import RecipeBody from "../components/recipes/RecipeBody"
import ImageCallout from "../components/home/ImageCallout"
import Seo from "../components/seo"

export const query = graphql`
  query Recipe($id: String) {
    contentfulRecipe(id: { eq: $id }) {
      slug
      recipeName
      recipeDescription {
        raw
      }
      customerFavorite
      alcoholic
      instructions {
        raw
      }
      ingredients {
        raw
      }
      recipeImage {
        gatsbyImage(width: 640, height: 480, placeholder: BLURRED)
      }
    }
  }
`

export default function RecipeTemplate({ data }) {
  const { contentfulRecipe: recipe } = data

  return (
    <Layout>
      <Seo title={recipe.recipeName} />

      <RecipeHeader
        name={recipe.recipeName}
        description={recipe.recipeDescription.raw}
        image={recipe.recipeImage.gatsbyImage}
        alcoholic={recipe.alcoholic}
      />
      <RecipeBody
        ingredients={recipe.ingredients.raw}
        instructions={recipe.instructions.raw}
      />
      <ImageCallout />
    </Layout>
  )
}

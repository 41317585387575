import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import colors from "../../styles/colors"
import richText from "../../utils/richText"
import { Body, BodyMedium, Header2, MetaLarge } from "../typography"
import typography from "../../styles/typography"
import breakpoints from "../../styles/breakpoints"

const Wrapper = styled.div`
  color: ${colors.creamLight};

  max-width: 1440px;
  margin: 0 auto;
`

const Header = styled.div`
  padding: 32px 24px;
  text-align: center;

  position: relative;

  ${MetaLarge} {
    display: block;
    margin: 0 auto 24px;

    padding: 8px 16px;

    width: fit-content;

    border-radius: 16px;

    border: 1px solid ${colors.creamLight};
  }

  &:after {
    content: "";

    position: absolute;
    left: 50%;
    bottom: 0;

    transform: translateX(-50%);

    height: 1px;
    width: 100vw;

    background: ${colors.creamLight};
  }

  @media ${breakpoints.tablet} {
    padding: 80px 40px;
  }
`

const Content = styled.div`
  padding: 40px 24px;

  position: relative;

  ${Body} {
    margin-top: 40px;
    ${typography.bodyMedium.desktop}
  }

  &:after {
    content: "";

    position: absolute;
    left: 50%;
    bottom: 0;

    transform: translateX(-50%);

    height: 1px;
    width: 100vw;

    background: ${colors.creamLight};
  }

  @media ${breakpoints.tablet} {
    display: flex;

    max-width: 1440px;

    flex-direction: row-reverse;

    > div {
      flex: 1 1 50%;
    }

    > div:last-child {
      display: flex;

      align-items: flex-end;

      > * {
        max-width: 400px;
      }
    }
  }
`

export default function RecipeHeader({ name, image, description, alcoholic }) {
  return (
    <Wrapper>
      <Header>
        <MetaLarge>{alcoholic ? "Low-proof" : "Zero-proof"}</MetaLarge>
        <Header2>{name}</Header2>
      </Header>
      <Content>
        <GatsbyImage image={image} alt={name} />
        <div>{richText(description)}</div>
      </Content>
    </Wrapper>
  )
}
